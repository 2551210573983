import axios from "axios";
import { config } from "../config";

const HOST_API = config.HOST_API;

const apiHelper = async (url, method, data, headers) => {
  try {
    // ตรวจสอบและเข้ารหัส URL
    const safeUrl = (() => {
      try {
        return encodeURI(url); // เข้ารหัส URL
      } catch (e) {
        console.error("Malformed URL detected:", url);
        throw new Error("Malformed URL");
      }
    })();

    console.log("Request Details:", {
      url: safeUrl,
      method,
      data,
      headers,
    });

    // ส่ง request
    const safeData =
      data && typeof data === "object" ? JSON.stringify(data) : data;

    const response = await axios({
      url: safeUrl,
      method,
      data: safeData,
      headers,
    });
    return response.data;
  } catch (error) {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.errorMessage === "access token expired"
    ) {
      const checkRefreshedTokens = await refreshTokens(data, headers);
      if (checkRefreshedTokens) {
        console.log("Tokens refreshed successfully:", checkRefreshedTokens);

        // Retry the original request
        const newHeaders = {
          ...headers,
          Authorization: `${checkRefreshedTokens.accessToken}`,
        };
        const newData = {
          ...data,
          refreshToken: checkRefreshedTokens.refreshToken,
        };

        // ต้องประกาศ safeUrl ซ้ำในกรณี retry request
        const retrySafeUrl = encodeURI(url);
        const retryResponse = await axios({
          url: retrySafeUrl,
          method,
          data: JSON.stringify(newData),
          headers: newHeaders,
        });
        return retryResponse.data;
      }
    } else {
      console.error("API Error:", error.message);
      throw error;
    }
  }
};

// ฟังก์ชันสำหรับ refresh tokens
const refreshTokens = async (data, headers) => {
  try {
    console.log("Refreshing tokens with headers:", headers);
    const response = await axios.post(
      `${HOST_API}/api/auth/access-token`,
      data,
      { headers }
    );
    return response.data.tokens;
  } catch (error) {
    console.error("Error refreshing tokens:", error.message);
    throw error;
  }
};

export default apiHelper;
